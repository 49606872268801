import React, { useState, useEffect } from 'react'
import Navbarv2 from '../global-components/Navbarv2'
import linkIn from '../../assets/img/linked.png';
import facebook from '../../assets/img/facebook.png';
import twitter from '../../assets/img/twitter.png';
import Footer from '../global-components/Footer';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const SingleBlog = () => {

  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
    // process.env.REACT_APP_ADMIN_URL + 
    fetch(`https://admin.aeroconsultant.fr/api/blogs/${id}`, {
      headers: {
        "X-API-Key": apiKey,
      },
    })
      .then(response => {
        if (response.status === 404) {
          setNotFound(true);
          return null;
        }
        return response.json();
      })
      .then(data => {
        if (!notFound) {
          setBlog(data);
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, [id, notFound]);

  if (notFound) {
    return <div>Blog post not found</div>;
  }

  if (!blog) {
    return <div className='d-flex align-items-center justify-content-center' style={{width: '100%', height: '100vh'}}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>;
  }


  console.log(blog);

  return (
    <>
      <Navbarv2 />
      {/* Blog detail Start  */}
      <div className='container mt-2 mb-5' style={{ width: '1100px' }}>
        <nav className='container mt-4' aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-primary'>Home</Link></li>
            <li className="breadcrumb-item"><Link to="/blogs" className='text-decoration-none text-primary'>Blogs</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{blog.title}</li>
          </ol>
        </nav>
        <h2 className='font-family'>{blog.title}</h2>
        <img src={'https://admin.aeroconsultant.fr/images/' + blog.image} className='my-3 rounded-5 w-100' alt='blog-post' />
        <div className='row'>
          <div className='col-md-8'>
            <div className='border-bottom py-3 mb-3 d-flex align-items-center flex-wrap'>
              <div>
                <h5 className='font-family-2'>{blog.category}</h5>
              </div>
              <div className='ms-5'>
                <div className='d-flex align-items-center text-secondary'>
                  <i className='material-icons'>calendar_month</i>
                  <p className='p-0 m-0 ms-2 fw-bold'>{blog.created_at}</p>
                </div>
              </div>
              <div className='ms-5'>
                <div className='d-flex align-items-center text-secondary'>
                  <i className='material-icons'>schedule</i>
                  <p className='p-0 m-0 ms-2 fw-bold'>{blog.readTime} min read</p>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <h6 className='font-family-2'>{blog.subtitle}</h6>
              <div dangerouslySetInnerHTML={{ __html: blog.description }} />
            </div>
            <hr />
            {/* <div>
                        <div className='d-flex w-100 align-items-center'>
                            <h6 className='p-0 m-0'>Share :</h6>
                            <div className='mx-3'>
                                <img src={facebook} className='me-2' width={25} alt='blog-post'/>
                                <img src={linkIn} className='me-2' width={25} alt='blog-post'/>
                                <img src={twitter} className='me-2' width={25} alt='blog-post'/>
                            </div>
                        </div>
                    </div> */}
          </div>
          <div className='col-md-4 pt-3'>
            <div style={{ position: 'sticky', top: '150px' }} className='mt-5'>
              <div className='border bg-white p-3 rounded-5'>
                <h5 className='font-family-2'>Stay Informed</h5>
                <p className='text-secondary'>Subscribe to our newsletter and be the first to see the latest posts and tips.</p>
                <form className="d-flex align-items-center  border border-secondary rounded-pill p-2">
                  <input className="form-control news-letter shadow-0" type="text" placeholder="Your email" />
                  <button className="btn btn-primary rounded-pill" type="button">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </>
  )
}

export default SingleBlog
