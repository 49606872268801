import React from 'react'
import { Link } from 'react-router-dom';


const BlogCard = (props) => {
  return (
    <>
        <div className='blog-card'>
            <img src={props.img}  className='rounded-5 w-100 border shadow-sm' alt='Blog-img' style={{height: '250px'}}/>
            <div className='rounded-3 bg-purple my-3 px-3 py-1 d-flex align-items-center justify-content-between'>
                <h6 className='font-family p-0 m-0'>{props.category}</h6>
                <p className='p-0 m-0 fw-bold text-secondary'>{props.readTime}min read</p>
            </div>
            <h5 className='font-family-2'>{props.title}</h5>
            <p className='text-secondary'>{props.subtitle}</p>
            <div className='mt-3 next d-flex text-dark-2 align-items-center'>
              <Link to={`/blog/${props.id}`} className='p-0 m-0 h6 text-decoration-none text-dark'>
              Read full article
              </Link>
            <span className='material-icons p-0 m-0 ms-2'>arrow_forward</span>
            </div>
        </div> 
    </>
  )
}

export default BlogCard
