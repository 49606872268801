import React from 'react'
import Navbar from '../global-components/Navbar'
import sliderImg2 from '../../assets/img/02.jpg';
import textVec from '../../assets/img/ve1org.png';
import textVec2 from '../../assets/img/vec2org.png';
import vector1 from '../../assets/img/vec1.png';
import vector2 from '../../assets/img/vec2.png';
import Footer from '../global-components/Footer';
import { Link } from 'react-router-dom';


const Services = () => {
  return (
    <> 
        <Navbar/>

      

        <div id='service-page-header'>
        
            <div className='container' id='service-text-section' style={{paddingTop: '120px'}}>
            
            <nav className='container mt-4' aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-primary'>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/services" className='text-decoration-none text-secondary'>Service</Link></li>
                </ol>
            </nav>

                <div className='row pt-5'>
                    <div className='col-lg-8'>
                        <div className='d-flex'>
                            <div className=''>
                                <img src={textVec}  width={30}/>
                                <h1 style={{fontSize: '55px'}} className='font-family text-white header-heading ms-4 p-0 m-0'>We make you'r business interesting</h1>
                                <div className='text-end'>
                                    <img src={textVec2}  width={200} className='me-5'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 d-flex align-items-center'>
                        <div>
                            <p className='text-white'>make your business more attractive and unique in eyes of each of uours customers to reach a wider market and build a better business</p>
                            <button className="btn btn-light px-4 me-2 rounded-pill" type="button" id='light-btn'>Getting Started</button>
                        </div>
                    </div>
                </div>
                <div className='mt-5 d-flex align-items-center justify-content-center'>
                    <div className='text-center'>
                        <img src={sliderImg2} className='rounded-5 mx-auto position-relative w-100' style={{top: '20px', width: '65%'}} alt='Services'/>
                    </div>
                    <div className='play-button p-3 position-absolute' data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className='material-icons fs-1'>play_arrow</i>
                    </div>
                </div>
            </div>
        </div>



    {/* video Modal  */}
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog rounded-5 modal-dialog-centered modal-xl">
            <div className="modal-content rounded-5">
                <div className="modal-body p-0 rounded-5 m-0">
                    <iframe width="100%" height="700" className='rounded-5 p-0 m-0' src="https://www.youtube.com/embed/watch?v=D89Dgg32yLk&list=PLU9il4c3BBUXS1IsXq8FXx-UQFb22Kge5" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}

    <div style={{marginTop: '650px'}} id='service-sub-sec' className='mb-5 container'>


        {/* Services Counter  */}
        <div className='container rounded-5 py-4 px-5 row'>
            <div className='text-center col-md-3 my-3'>
                <h3 className='font-family'>130+</h3>
                <h6>Happy Customers</h6>
            </div>
            <div className='text-center col-md-3 my-3'>
                <h3 className='font-family'>100+</h3>
                <h6>Best Service Provide</h6>
            </div>
            <div className='text-center col-md-3 my-3'>
                <h3 className='font-family'>530+</h3>
                <h6>Service Sales</h6>
            </div>
            <div className='text-center col-md-3 my-3'>
                <h3 className='font-family'>30+</h3>
                <h6>Our Business Partners</h6>
            </div>
        </div>


        <div className='d-flex justify-content-center mt-3'>
            <div className='w-75 ceo'>
                <figcaption className="blockquote-footer fs-5 text-center text-white">
                    <cite title="Source text-white">Our Team</cite>
                </figcaption>
                <div className='w-50 text-center'>
                    <img src={vector1} className='me-5' width={30}/>
                </div>
                <h2 className='font-family text-center text-black ms-5 p-0 m-0'>We just offer the best services</h2>
                <div className='text-end'>
                    <img src={vector2}  width={200} className='me-5'/>
                </div>
            </div>
        </div>


        <div className='row my-5 bg-light rounded-5 p-3 py-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className='num-count border-end me-4 pe-4'>
                    <h1>01</h1>
                </div>
                <div className='text-center'>
                    <img src={sliderImg2} className='rounded-5 w-100' alt='Services'/>
                </div>
            </div>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div>
                    <h3 className='font-family-2'>Pool of Aircraft/Helicopter Contractor in 155 Countries</h3>
                    <p className='text-secondary'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
        </div>



        <div className='row my-5 p-3 py-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div>
                    <h3 className='font-family-2'>Onsite Team for FAL Support in Hamburg & Toulouse</h3>
                    <p className='text-secondary'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className='text-center'>
                    <img src={sliderImg2} className='rounded-5 w-100' alt='Services'/>
                </div>
                <div className='num-count border-start ms-4 ps-4'>
                    <h1>02</h1>
                </div>
            </div>
        </div>


        <div className='row my-5 bg-light rounded-5 p-3 py-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className='num-count border-end me-4 pe-4'>
                    <h1>03</h1>
                </div>
                <div className='text-center'>
                    <img src={sliderImg2} className='rounded-5 w-100' alt='Services'/>
                </div>
            </div>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div>
                    <h3 className='font-family-2'>Approved CAMO for Airbus (A320, A330 & A350), Boeing (B737 NG & Max) & ATR-72</h3>
                    <p className='text-secondary'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
        </div>



        <div className='row my-5 p-3 py-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div>
                    <h3 className='font-family-2'>Passenger to Frighter Conversion representation in USA & China</h3>
                    <p className='text-secondary'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className='text-center'>
                    <img src={sliderImg2} className='rounded-5 w-100' alt='Services'/>
                </div>
                <div className='num-count border-start ms-4 ps-4'>
                    <h1>04</h1>
                </div>
            </div>
        </div>




        <div className='row my-5 bg-light rounded-5 p-3 py-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className='num-count border-end me-4 pe-4'>
                    <h1>05</h1>
                </div>
                <div className='text-center'>
                    <img src={sliderImg2} className='rounded-5 w-100' alt='Services'/>
                </div>
            </div>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div>
                    <h3 className='font-family-2'>Digital Products three slide or oneslide with three product</h3>
                    <p className='text-secondary'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
        </div>



        <div className='row my-5 p-3 py-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div>
                    <h3 className='font-family-2'>License & Non-License Mechanics who will perform base & line maintenance</h3>
                    <p className='text-secondary'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className='text-center'>
                    <img src={sliderImg2} className='rounded-5 w-100'alt='Services'/>
                </div>
                <div className='num-count border-start ms-4 ps-4'>
                    <h1>06</h1>
                </div>
            </div>
        </div>


    </div>

    <Footer/>
    </>
  )
}

export default Services
