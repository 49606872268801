import React, { useEffect, useState } from 'react';
import Navbar from '../global-components/Navbar'
import vector1 from '../../assets/img/vec1.png';
import vector2 from '../../assets/img/vec2.png';
import vector1org from '../../assets/img/ve1org.png';
import vector2org from '../../assets/img/vec2org.png';
import founder1 from '../../assets/img/neha.jpeg';
import founder2 from '../../assets/img/akash.jpeg';
import aero from '../../assets/img/down-aero.png';
import ServiceCard from '../section-components/ServiceCard';
import TeamCard from '../section-components/TeamCard';
import Footer from '../global-components/Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ClientsCard from '../section-components/ClientsCard';
import { Link } from 'react-router-dom';








const Home = () => {


    // Sliders Fetching
    const [sliders, setSliders] = useState([]);
    useEffect(() => {
        const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
        fetch( "https://admin.aeroconsultant.fr/api/sliders", {
            headers: {
                "X-API-Key": apiKey,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Group products by ID
                const groupedData = data.reduce((acc, curr) => {
                    if (!acc[curr.id]) {
                        acc[curr.id] = { ...curr, products: [] };
                    }
                    if (curr.title && curr.description) {
                        acc[curr.id].products.push({
                            title: curr.title,
                            description: curr.description,
                        });
                    }
                    return acc;
                }, {});

                // Convert object back to array
                const transformedData = Object.values(groupedData);
                setSliders(transformedData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);


    const [clients, setClients] = useState([]);
    useEffect(() => {
        const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
        fetch("https://admin.aeroconsultant.fr/api/clients", {
            headers: {
                "X-API-Key": apiKey,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setClients(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);


    const clientCardsSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Number of slides shown on larger screens
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024, // Screen width at which the settings below will be applied
                settings: {
                    slidesToShow: 3, // Number of slides shown on screens with width less than or equal to 1024px
                },
            },
            {
                breakpoint: 768, // Screen width at which the settings below will be applied
                settings: {
                    slidesToShow: 2, // Number of slides shown on screens with width less than or equal to 768px
                },
            },
            {
                breakpoint: 480, // Screen width at which the settings below will be applied
                settings: {
                    slidesToShow: 1, // Number of slides shown on screens with width less than or equal to 480px
                },
            },
        ],
    };

    const [showHeading, setShowHeading] = useState(false);
    useEffect(() => {
        setShowHeading(true);
    }, []);


    return (
        <>
            <Navbar />

            {/* main banner  */}
            <div className=''>
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {sliders.map((slider, index) => (
                            <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"} style={{ clipPath: 'path(m 0 0 v 100 c 70 30 140 30 210 0 V 0 Z)' }}>
                                <div className='banner-img' style={{
                                    background: `url("https://admin.aeroconsultant.fr/images/${encodeURIComponent(slider.image)}")`,
                                    backgroundSize: 'cover', height: '100vh', width: '100%', transition: 'background-size 0.3s ease'
                                }}>
                                    <div style={{ height: '100vh', width: '100%', background: 'rgb(1 2 36 / 74%)' }}>
                                        <div className='container d-flex align-items-center justify-content-center h-100'>
                                            <div className='position-relative'>
                                                <div className='text-center'>
                                                    <div className='px-5'>
                                                        <h1 style={{ fontSize: '35px' }} className='header-heading text-white font-family p-0 m-0' dangerouslySetInnerHTML={{ __html: slider.heading }} />
                                                        {
                                                            index === 0 && (
                                                                <Link to={'/contact'} className="btn btn-primary px-4 fs-5 mt-3 hero-btn" type="button">Join Us</Link>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {/* service products */}

                                                {
                                                    slider.products && (
                                                        <div className='row px-5' style={{ position: 'relative', bottom: '-40px' }}>
                                                            {
                                                                slider.products.map((product, index) => (
                                                                    <div className='col-md-4' key={index}>
                                                                        <div class=" text-start product-card">
                                                                            <div class="card-body">
                                                                                <img src={vector1} style={{ width: '20px' }} />
                                                                                <h5 class="card-title font-family ms-3 m-0 p-0">{product.title} </h5>
                                                                                <img src={vector2} style={{ width: '250px' }} className='' />
                                                                                <p class="card-text">{product.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev my-auto border mx-3 p-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon text-dark" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next my-auto border me-3 p-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon text-dark" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>


            </div>


            {/* down up aero */}
            <div className='social-media-link w-100' style={{ position: 'absolute', bottom: '20px' }}>
                <div className='w-100 text-center arrow-container'>
                    <a href='#secSection'>
                        <img src={aero} className="down-aero-hero " width={65} />
                    </a>
                </div>
            </div>



            {/* Forum */}
            {/* <div className='container mb-5 py-5' style={{marginTop: '120px'}}>

        
        <div className='row g-5 py-4 rounded-5'>
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className=''>
                    <figcaption className="blockquote-footer fs-5 text-primary">
                        <cite title="Source Title">Community Forum</cite>
                    </figcaption>
                        <img src={vector1}  width={30}/>
                    <div className=''>
                        <h2 className='font-family p-0 m-0'>Business opportunities are like buses, there's always another  one coming Soon...</h2>
                        <div className='text-end'>
                            <img src={vector2}  width={200} className=''/>
                        </div>
                        <p className='text-secondary my-4'>Build brand and grow your business Build brand and grow your business grow your business with Aero Consultant ld brand and grow your business with Aero Consultant B</p>
                        <ul className='text-secondary'>
                            <li>Build brand and grow your business</li>
                            <li>Build brand and grow your business</li>
                            <li> your business with Aero Consultant ld</li>
                        </ul>
                        <button className="btn btn-primary mt-4 d-flex align-items-center next me-2 px-4 hero-btn" type="button">Join Our Community
                            <span className='material-icons p-0 m-0 ms-1'>arrow_forward</span>
                        </button>

                    </div>
                </div>
            </div>
            <div className='col-lg-6 px-0 my-3 ' id='secSection'>
                <img src={comm}  className='community-desc-img'/>
            </div>
            
        </div>


    </div> */}


            {/* <div className='container mb-5 py-5' style={{marginTop: '120px'}}>

        
        <div className='row g-5 py-4 rounded-5'>
        
            <div className='col-lg-6 my-3 d-flex align-items-center'>
                <div className=''>
                    <figcaption className="blockquote-footer fs-5 text-primary">
                        <cite title="Source Title">Community Forum</cite>
                    </figcaption>
                        <img src={vector1}  width={30}/>
                    <div className=''>
                        <h2 className='font-family p-0 m-0'>Business opportunities are like buses, there's always another  one coming Soon...</h2>
                        <div className='text-end'>
                            <img src={vector2}  width={200} className=''/>
                        </div>
                        <p className='text-secondary my-4'>Build brand and grow your business Build brand and grow your business grow your business with Aero Consultant ld brand and grow your business with Aero Consultant B</p>
                        <ul className='text-secondary'>
                            <li>Build brand and grow your business</li>
                            <li>Build brand and grow your business</li>
                            <li> your business with Aero Consultant ld</li>
                        </ul>
                        <button className="btn btn-primary mt-4 d-flex align-items-center next me-2 px-4 hero-btn" type="button">Join Our Community
                            <span className='material-icons p-0 m-0 ms-1'>arrow_forward</span>
                        </button>

                    </div>
                </div>
            </div>
            
        </div>


    </div> */}


            {/* services . */}
            <div className='py-5 container mt-5' >
                <div className='mt-5'>
                    <div className='d-flex justify-content-center'>
                        <div className='w-50 home-titles'>
                            <figcaption className="blockquote-footer text-center fs-5 text-primary">
                                <cite title="Source Title ">See Our Services</cite>
                            </figcaption>
                            <img src={vector1} width={30} />
                            <div className=''>
                                <h2 className='font-family ms-4 p-0 m-0'>We Offer Wide Range Of Services</h2>
                                <p className='text-center text-secondary'>Pool of aircraft/Helicopter Contractors in 155 countries</p>
                                <div className='text-end'>
                                    <img src={vector2} width={200} className='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container' id="services">
                        <ServiceCard />
                    </div>

                </div>
            </div>


            {/* CEO's */}
            <div className='bg-dark-shade pb-5'>
                <div className='container my-5 pt-5' id='team'>
                    <div className='d-flex justify-content-center'>
                        <div className='w-50 ceo'>
                            <figcaption className="blockquote-footer fs-5 text-center text-white">
                                <cite title="Source text-white">Our Team</cite>
                            </figcaption>
                            <img src={vector1org} width={30} />
                            <h2 className='font-family text-white ms-4 p-0 m-0'>Meet With Our Expert Team</h2>
                            <div className='text-end'>
                                <img src={vector2org} width={200} className='me-5' />
                            </div>
                        </div>
                    </div>

                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active  text-center">
                                <TeamCard img={founder1} name='Neha MISHRA' linkedIn='https://www.linkedin.com/in/nehamishratbs/' desig='Founder / Chief Technical Officer' about='Started her journey back in 2006 as
                            an aircraft technician in India and become one of the youngest engineers back then. She
                            moved into the leasing market a decade ago and since then she managed and led multiple
                            teams and companies before starting and expanding Aero Consultant.'/>
                            </div>
                            <div className="carousel-item text-center">
                                <TeamCard img={founder2} name='Rohit KUMAR' linkedIn='https://www.linkedin.com/in/rohitkuam/' desig='Founder / Chief Commercial Officer' about='A visionary leader driving
                            our company&#39;s commercial success. With deep market knowledge and strategic prowess,
                            Rohit inspires our talented team to exceed targets and foster strong client relationships.
                            Under his guidance, we consistently deliver outstanding results, maximizing revenue and
                            market share.'/>
                            </div>
                        </div>
                        <button className="carousel-control-prev my-auto border rounded-circle" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next my-auto border rounded-circle" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </div>


            {/* Meet the team member */}
            {/* 
    <div className='container' style={{marginTop: '120px'}}>
        <div className='d-flex justify-content-center'>
            <div className='w-50 team-members'>
                <figcaption className="blockquote-footer text-center fs-5 text-primary">
                    <cite title="Source Title ">Meet Team Member</cite>
                </figcaption>
                    <img src={vector1}  width={30}/>
                <div className=''>
                    <h2 className='font-family ms-4 p-0 m-0'>Meet the Team Member's</h2>
                    <div className='text-end'>
                        <img src={vector2}  width={200} className=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className=''>
            <Slider {...settings}>
                <MainTeamCard img={user3} name='Ranveer' clas='tran-y'/>
                <MainTeamCard img={user2} name='Akash'/>
                <MainTeamCard img={user1} name='Neha' clas='tran-y'/>
                <MainTeamCard img={user4} name='Youmna'/>
                <MainTeamCard img={user2} name='Akash' clas='tran-y'/>
            </Slider>
        </div>

    </div> */}


            {/* <div className='mt-5'>
        <div className='container my-5 pt-5'>
                
                <div className='row g-5'>
                    <div className='col-md-6 px-0'>
                        <img src={aboutBanner}  id='banner-img' width={'100%'}/>
                    </div>
                    <div className='col-md-6 d-flex align-items-center'>
                        <div className=''>
                            <figcaption className="blockquote-footer fs-5 text-primary">
                                <cite title="Source Title">EST 1996</cite>
                            </figcaption>
                                <img src={vector1}  width={30}/>
                            <div className=''>
                                <h4 className='font-family p-0 m-0'>Business opportunities are like buses, <br /> there's always another  one coming.</h4>
                                <div className='text-end'>
                                    <img src={vector2}  width={200} className=''/>
                                </div>
                                <p className='text-secondary my-4'>Build brand and grow your business with Aero Consultant Build brand and grow your business with Aero Consultant ld brand and grow your business with Aero Consultant B</p>
                                <div className='mt-3 next d-flex text-dark-2 align-items-center'>
                                    <h6 className='p-0 m-0 '>Check out more
                                    </h6>
                                    <span className='material-icons p-0 m-0 ms-3'>arrow_forward</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div> */}



            {/* our clients */}
            <div className='container' id='clients'>

                <div className='d-flex justify-content-center'>
                    <div className='w-50 home-titles'>
                        <figcaption className="blockquote-footer text-center fs-5 text-primary">
                            <cite title="Source Title ">Our Clients</cite>
                        </figcaption>
                        <img src={vector1} width={30} />
                        <div className='text-center'>
                            <h2 className='font-family ms-4 p-0 m-0'>Our happy Clients</h2>
                            <div className='text-end'>
                                <img src={vector2} width={200} className='' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <Slider {...clientCardsSettings}>
                        {
                            clients.map((client, index) => (
                                <ClientsCard key={index} img={client.image} />
                            ))
                        }
                    </Slider>
                </div>
            </div>

            <Footer />

        </>
    )
}

export default Home
