import React from 'react'
import service2 from '../../assets/img/service2.jpg';
import service3 from '../../assets/img/service3.jpg';
import service4 from '../../assets/img/service4.jpg';
import service5 from '../../assets/img/service5.png';
import servec from '../../assets/img/01.jpeg';
import vector from '../../assets/img/vec1.png';
import aero from '../../assets/img/aero.png';
import mainServiceImg1 from '../../assets/img/service-main.jpg';



const ServiceCard = (props) => {



  return (
    <>
    
         <div className='row g-5 bg-light mt-5 position-relative main-servicwe-section p-3 rounded-5'>
            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                <div className='num-count'>
                    <h1>01</h1>
                </div>
                <div className='service-card bg-white shadow mx-3'>
                  <div className='card-body'>
                      <img src={mainServiceImg1} className='w-100 position-relative' style={{top: '-50px',boxShadow: '#00000033 0px 14px 34px',  borderRadius: '15px'}}/>
                      <h2 className='font-family-2'>Pool of aircraft/Helicopter Contractors in 155 countries</h2>
                      <button className="btn btn-outline-primary px-4 hero-btn-2 mt-3 rounded-pill" id='dark-btn' type="button">Join Now</button>
                  </div>
                </div>
            </div>
            <div className='col-md-6 px-5 padding-0 d-flex align-items-center'>
                <div>
                    <img src={aero} width={200} className='mb-3'/>
                    <div className='text-start'>
                        <img src={vector} style={{width: '30px'}} className=''/>
                    </div>
                    <h1 className='font-family-2'>What our Network will do for you</h1>
                    <div>
                        <div className=''>
                            <p className='p-0 m-0 mb-1 d-flex align-items-center'>
                                <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                               <span className='ms-4 ps-2'> Aircraft Transition Management</span>
                            </p>
                            <p className='p-0 m-0 mb-1 d-flex align-items-center'>
                                <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                               <span className='ms-4 ps-2'>Mid-Term/ Annual Audit Support</span>
                            </p>
                            <p className='p-0 m-0 mb-1 d-flex align-items-center'>
                                <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                               <span className='ms-4 ps-2'> New Aircraft Delivery</span>
                            </p>
                            <p className='p-0 m-0 mb-1 d-flex align-items-center'>
                                <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                               <span className='ms-4 ps-2'> Record Digitalisation Support</span>
                            </p>
                            <p className='p-0 m-0 mb-1 d-flex align-items-center'>
                                <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                               <span className='ms-4 ps-2'> Off-Site Records Review</span>
                            </p>
                            <p className='p-0 m-0 mb-1 d-flex align-items-center'>
                                <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                               <span className='ms-4 ps-2'> Powerplant Management</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>


            <div className='col-md-6 px-5 padding-0 d-flex align-items-center margin-top'>
                <div>
                    <div className='text-start'>
                        <img src={vector} style={{width: '30px'}} className=''/>
                    </div>
                    <h1 className='font-family-2'>Final Assembly Line support</h1>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>Dedicated team in Toulouse &amp; Hamburg for on-demand ad-hoc inpection Request</span>
                    </p>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'> Our Digital portal Aerobox will provide transfer &amp; inspection updates in real-time</span>
                    </p>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>Real-time follow up of the status of aircarft in assembly line</span>
                    </p>
                </div>
            </div>
            <div className='col-md-6 d-flex align-items-center justify-content-center margin-top'>
                <div className='num-count'>
                    <h1>02</h1>
                </div>
                <div className='service-card bg-white shadow mx-3'>
                  <div className='card-body'>
                      <img src={service2} className='w-100 position-relative' style={{top: '-50px',boxShadow: '#00000033 0px 14px 34px',  borderRadius: '15px'}}/>
                      <h2 className='font-family-2'>FAL support</h2>
                  </div>
                </div>
            </div>



            <div className='col-md-6 d-flex align-items-center margin-top'>
                <div className='num-count'>
                    <h1>03</h1>
                </div>
                <div className='service-card bg-white shadow mx-3'>
                  <div className='card-body'>
                  <img src={service3} className='w-100 position-relative' style={{top: '-50px',boxShadow: '#00000033 0px 14px 34px',  borderRadius: '15px'}}/>
                      <h2 className='font-family-2'>EASA / Cayman Islands / N-REG CAMO Services</h2>
                  </div>
                </div>
            </div>
            <div className='col-md-6 px-5 padding-0 d-flex align-items-center margin-top'>
                <div>
                <img src={aero} width={200} className='mb-3'/>

                    <div className='text-start'>
                        <img src={vector} style={{width: '30px'}} className=''/>
                    </div>
                    <h3 className='font-family-2'>Licensed Camo Provider for</h3>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>Full Scope of CAMO Services including but not limited to Phase-In, Phase-Out, Airworthiness Maintenance</span>
                    </p>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>Aircraft Types: A318 | A319 | A320 | A321| A330 | A350 | B737 MAX | B737 NG | ATR 72</span>
                    </p>
                </div>
            </div>


            <div className='col-md-6 px-5 padding-0 d-flex align-items-center margin-top'>
                <div>
                
                    <div className='text-start'>
                        <img src={vector} style={{width: '30px'}} className=''/>
                    </div>
                    <h3 className='font-family-2'>Passenger to Freighter Conversion Services</h3>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>On-Site Representation to support your P2F requirements</span>
                    </p>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>Budget Control &amp; Co-ordination with all stakeholders</span>
                    </p>
                </div>
            </div>
            <div className='col-md-6 d-flex align-items-center margin-top'>
                <div className='num-count'>
                    <h1>04</h1>
                </div>
                <div className='service-card bg-white shadow mx-3'>
                  <div className='card-body'>
                  <img src={service4} className='w-100 position-relative' style={{top: '-50px',boxShadow: '#00000033 0px 14px 34px',  borderRadius: '15px', height: '250px'}}/>
                      <h2 className='font-family-2'>Aircraft P2F Conversion</h2>
                  </div>
                </div>
            </div>



            <div className='col-md-6 d-flex align-items-center margin-top'>
                <div className='num-count'>
                    <h1>05</h1>
                </div>
                <div className='service-card bg-white shadow mx-3'>
                  <div className='card-body'>
                      <img src={service5} className='w-100 position-relative bg-white' style={{top: '-50px',boxShadow: '#00000033 0px 14px 34px',  borderRadius: '15px'}}/>
                      <h2 className='font-family-2'>Aircraft Digital Services</h2>
                      <button className="btn btn-outline-primary px-4 hero-btn-2 mt-3 rounded-pill" id='dark-btn' type="button">Book A Demo</button>
                  </div>
                </div>
            </div>
            <div className='col-md-6 px-5 padding-0 d-flex align-items-center margin-top'>
                <div>
                <img src={aero} width={200} className='mb-3'/>

                    <div className='text-start'>
                        <img src={vector} style={{width: '30px'}} className=''/>
                    </div>
                    <h3 className='font-family-2'>Inter-operable, secure &amp; 24x7 Available</h3>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>AeroBox- Records depository based on the IATA ABC folder structure with enhanced AI
                        based search capability, auto OCR and can be accessed globally without a VPN.</span>
                    </p>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>AeroOPs – Helps you maintain your status sheet and links it with DFPs (Dirty Finger Prints) stored
                        in records storage system (AeroBox)</span>
                    </p>
                    <p className='p-0 m-0 mb-1 d-flex'>
                        <i className='material-icons p-0 m-0' style={{width: '0'}}>checked</i>
                        <span className='ms-4 ps-2'>AeroOIL - Open Item List software is connected to records stored in AeroBox or statuses
maintained in AeroOps. Automatically transfers all comments to the linked records.</span>
                    </p>
                </div>
            </div>


            {/* <div className='col-md-6 px-5 padding-0 d-flex align-items-center margin-top'>
                <div>
                    <div className='text-start'>
                        <img src={vector} style={{width: '30px'}} className=''/>
                    </div>
                    <h3 className='font-family-2'>Onsite Team for FAL Support in Hamburg & Toulouse</h3>
                    <p className='w-75'>Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries Pool of Aircraft/Helicopter Contractor in 155 Countries</p>
                </div>
            </div>
            <div className='col-md-6 d-flex align-items-center margin-top'>
                <div className='num-count'>
                    <h1>06</h1>
                </div>
                <div className='service-card bg-white shadow mx-3'>
                  <div className='card-body'>
                  <img src={servec} className='w-100 position-relative' style={{top: '-50px',boxShadow: '#00000033 0px 14px 34px',  borderRadius: '15px'}}/>
                      <h2 className='font-family-2 mt-3'>Onsite Team for FAL Support in Hamburg & Toulouse</h2>
                  </div>
                </div>
            </div> */}
           
         </div>
    </>
  )
}

export default ServiceCard
