import './assets/css/main.css'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import About from './components/pages/About';
import Blog from './components/pages/Blog';
import Contact from './components/pages/Contact';
import Home from './components/pages/Home';
import SingleBlog from '../src/components/section-components/SingleBlog';
import Services from './components/pages/Services';
import SignUp from './components/pages/SignUp';
import SignIn from './components/pages/SignIn';

function App() {

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  // Get the current URL
  const currentURL = window.location.href;
  console.log(currentURL);
  // Parse the URL to extract query parameters
  const urlParams = new URLSearchParams(currentURL.split('?')[1]);

  // Get the value of the redirect parameter
  const redirectURL = urlParams.get('redirect');

  const CustomLoginRoute = () => {
    // Redirect to abc.com/login
    window.location.href = redirectURL + '/auth/login';
    // You can also return a <Navigate to="/login" /> component if you prefer not to use window.location.href
    return null; // Return null to prevent rendering anything in this route
  };

  const CustomRegisterRoute = () => {
    // Redirect to abc.com/login
    window.location.href = redirectURL + '/auth/register';
    // You can also return a <Navigate to="/login" /> component if you prefer not to use window.location.href
    return null; // Return null to prevent rendering anything in this route
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/sign_in" element={<CustomLoginRoute />} />
          <Route path="/register" element={<CustomRegisterRoute />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog/:id" element={<SingleBlog />} />
          {/* <Route path="/blogs/:id" element={<SingleBlog />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
