import React from 'react'
import linkedIn from '../../assets/img/linked.png';
import { Link } from 'react-router-dom';

const TeamCard = (props) => {
  return (
    <>
        <div className="d-flex justify-content-center mt-5">
            <div className='card-body'>
              <img src={props.img} style={{height: '200px', width: '200px'}} className="border p-2 rounded-circle" alt="..." />
              <div className="card-body text-center">
                  <h5 className="card-title p-0 m-0 text-white">{props.name}</h5>
                  <p className='fs-lg text-secondary p-0 m-0'>{props.desig}</p>
                  <p className='fs-lg text-white p-0 m-0 mt-3 '>{props.about}</p>
                  <Link to={props.linkedIn} target='_blank'>
                    <img src={linkedIn} className='mt-3 rounded-2' width={40}/>
                  </Link>
              </div>
            </div>
        </div> 
    </>
  )
}

export default TeamCard
