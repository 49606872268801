import React, { useState, useEffect } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isResult, setIsResult] = useState(null)

  const handleSubmit = () => {
    const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";

    // Perform client-side validation
    const requiredFields = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "message",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      // Display an error message for missing fields
      console.error("Required fields are missing: " + missingFields.join(", "));
      return;
    }

    fetch("https://admin.aeroconsultant.fr/api/contacts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiKey,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response data if needed
        console.log(data.message);
        setIsResult(data)
        let inputs = document.querySelectorAll('.form-control');
        inputs.forEach(element => {
          element.value = "";
        });
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div>
        {
          isResult && (
            isResult.message === "Contact created successfully" && (
              <div class="alert alert-success alert-dismissible" role="alert">
                <strong>Success!</strong> We'ev reserved your message we will contact you within a hr.
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
            )
          )
        }
        <div className="row">
          <div className="col-md-6 my-2">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="First name"
              aria-label="First name"
              name="first_name" // Add unique name attribute for each input
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 my-2">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="Last name"
              aria-label="Last name"
              name="last_name" // Add unique name attribute for each input
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-12 my-2">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="Email"
              aria-label="Email"
              name="email" // Add unique name attribute for each input
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-12 my-2">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="Phone"
              aria-label="Phone"
              name="phone" // Add unique name attribute for each input
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-12 my-2">
            <textarea
              className="form-control rounded-5"
              placeholder="Leave a comment here"
              rows={6}
              name="message" // Add unique name attribute for the textarea
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="d-flex mt-3">
            <button
              className="btn btn-primary me-2 px-4 hero-btn"
              type="button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
