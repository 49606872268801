import React, { useEffect, useState, useRef } from 'react';

import logo from '../../assets/img/logo-light.png'
import logoDark from '../../assets/img/logo.png'
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {


  const [scrollY, setScrollY] = useState(0);
  const [navbarClass, setNavbarClass] = useState('');
  const [logoClass, setLogoClass] = useState('');
  const [transitionClass, setTransitionClass] = useState('');

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollY(scrollTop);
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 100) {
      setNavbarClass('nav-dark bg-white shadow sticky-top');
      setTransitionClass('navbar-transition');
      document.getElementById('logo-dark').style.display = 'block'
      document.getElementById('light-logo').style.display = 'none'
      document.getElementById('dark-btn').style.display = 'block'
      document.getElementById('light-btn').style.display = 'none'
    } else {
      document.getElementById('logo-dark').style.display = 'none'
      document.getElementById('light-logo').style.display = 'block'
      document.getElementById('dark-btn').style.display = 'none'
      document.getElementById('light-btn').style.display = 'block'
      setNavbarClass('');
      setTransitionClass('');
    }
  }, [scrollY]);

  const location = useLocation();
  const servicesRef = useRef(null);

  useEffect(() => {
    if (location.hash === '#services' && servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);


  return (
    <>
      <nav className={`navbar navbar-expand-lg fixed-top navbar-light ${navbarClass}`}>
        <div className="container ">
          <Link id='light-logo' className="navbar-brand" to="/">
            <img src={logo} width={250} />
          </Link>
          <Link id='logo-dark' className='navbar-brand' to="/">
            <img src={logoDark} width={200} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                <Link className="nav-link text-white" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item me-3">
                <a className='nav-link text-white' href="#services">Services</a>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link text-white" to="/about">About</Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link text-white" to="/blogs">Blog</Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link text-white" to="/contact">Contact</Link>
              </li>
              {location.pathname === '/' && <div ref={servicesRef}></div>}
            </ul>
            <div className="d-flex">
              <Link to='/sign_in' className="btn btn-outline-light px-4 me-2 rounded-pill" type="button" id='light-btn'>Log in</Link>
              <Link to='/sign_in' className="btn btn-outline-primary px-4 me-2 hero-btn-2 rounded-pill" id='dark-btn' type="button">Log in</Link>
              <Link to='/register' className="btn btn-primary me-2 px-4 hero-btn" type="button">Sign Up</Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
