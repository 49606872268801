import React, { useEffect, useState, useRef } from 'react';
import logo from '../../assets/img/logo-light.png'
import logoDark from '../../assets/img/logo.png'
import { Link, useLocation } from 'react-router-dom';


const Navbarv2 = () => {


  const [scrollY, setScrollY] = useState(0);
  const [navbarClass, setNavbarClass] = useState('');
  const [transitionClass, setTransitionClass] = useState('');


  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollY(scrollTop);
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 0) {
      setNavbarClass('sticky-top bg-white shadow');
      setTransitionClass('navbar-transition');
    } else {
      setNavbarClass('');
      setTransitionClass('');
    }
  }, [scrollY]);




  return (
    <>
      <nav className={`navbar navbar-expand-lg border-bottom navbar-light ${transitionClass} ${navbarClass}`}>
        <div className="container ">
          <Link className="navbar-brand" to="/">
            <img src={logoDark} width={250} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                <Link className="nav-link text-dark" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item me-3">
                <Link className='nav-link text-dark' to="#services">Services</Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link text-dark" to="/about">About</Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link text-dark" to="/blogs">Blog</Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link text-dark" to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="d-flex">
              <Link to='/sign_in' className="btn btn-outline-primary px-4 me-2 hero-btn-2 rounded-pill" id='dark-btn' type="button">Log in</Link>
              <Link to='/register' className="btn btn-primary me-2 px-4 hero-btn" type="button">Sign Up</Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbarv2
