import React, { useState, useEffect } from 'react'
import textVec from '../../assets/img/vec1.png';
import textVec2 from '../../assets/img/vec2.png';
import banner2 from '../../assets/img/hero-banner.png';
import Navbarv2 from '../global-components/Navbarv2';
import banner from '../../assets/img/about-banner.png';
import vector1org from '../../assets/img/ve1org.png';
import vector2org from '../../assets/img/vec2org.png';
import bannerShadow from '../../assets/img/banner-shadow.png';
import Footer from '../global-components/Footer';
import WeChooseCard from '../section-components/WeChooseCard';
import { Link } from 'react-router-dom';


const About = () => {

    const [bannerImageTitle, setBannerImageTitle] = useState(null)
    useEffect(() => {
        const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
        fetch("https://admin.aeroconsultant.fr/api/about-page-image", {
            headers: {
                "X-API-Key": apiKey,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setBannerImageTitle(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    
  return (
    <>
        <Navbarv2/>
        <nav className='container mt-4' aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-primary'>Home</Link></li>
                <li className="breadcrumb-item"><Link to="/about" className='text-decoration-none text-secondary'>About</Link></li>
            </ol>
        </nav>

        <div className='px-4'>
            <div className='bg-light rounded-5'>
                <div className='container pt-5 position-relative my-4' id='about-banner'>
                    <div className='row' >
                        <div style={{zIndex: '100'}} className='col-lg-6 d-flex align-items-center'>
                            <div className='mb-5 pb-5'>
                                <div className='w-25'>
                                    <img src={textVec}  width={30}/>
                                </div>
                                <h2 style={{fontSize: '45px'}} className='font-family header-heading'>{bannerImageTitle !== null ? bannerImageTitle[0].title : "We build bridges between companies and customers"}</h2>
                                <div className='text-start position-relative'>
                                    <img src={textVec2} style={{transform: 'rotate(124deg)', top: '-120px', left: '-80px'}} width={200} className='position-absolute'/>
                                </div>
                                <Link to={'/contact'} className="btn btn-primary me-2 py-2 rounded-pill mt-5 px-4 hero-btn" type="button">Get Started</Link>
                            </div>
                        </div>
                        <div style={{zIndex: '100'}} className='col-lg-6 d-flex align-items-center'>
                            {
                                bannerImageTitle !== null && (
                                    <img src={`https://admin.aeroconsultant.fr/images/${bannerImageTitle[0].image ? bannerImageTitle[0].image : banner}`}  width={'120%'} className='service-banner-img'/>
                                )
                            }
                        </div>
                    </div>
                </div> 
                <img style={{top: '300px', zIndex: '1'}} src={bannerShadow} width={'100%'} className='position-absolute banner-shadow'/>
            </div>
        </div>

        {/* Our Features */}
        <div className='container mb-5' style={{marginTop: '130px'}}>
            {/* <div className='text-center'>
                <figcaption className="blockquote-footer fs-5 text-primary">
                    <cite title="Source Title ">Features</cite>
                </figcaption>
            </div> */}

            <div className='row mt-5 pt-3'>
                <div className='col-md-6'>
                    <div>
                        <img src={banner2} className='w-100'/>
                    </div>
                </div>
                <div className='col-md-6 d-flex align-items-center'>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <div className='ceo'>
                                <img src={textVec}  width={30}/>
                                <h2 className='font-family text-dark ms-4 p-0 m-0'>Welcome to AeroConsultant</h2>
                                <div className='text-end'>
                                    <img src={textVec2}  width={200} className='me-5'/>
                                </div>
                            </div>
                        </div>
                        <p className='text-secondary mt-4'>Welcome to AeroConsultant, your premier destination for your Aircraft asset management, CAMO &amp;
Aircraft Digital service. With a dedication to excellence and a passion for delivering exceptional
results, we are proud to be your trusted partner in Aviation.</p>
                    </div>
                    
                </div>
            </div>
        </div>


        <div className='bg-dark-shade mb-5 pb-5 pt-1' style={{marginTop: '180px'}}>
            <div className='container'>
                <div className='row mt-5'>
                
                    <div className='col-md-6 text-white d-flex align-items-center'>
                        <div>
                            <div className='d-flex justify-content-center'>
                                <div className='ceo'>
                                    <img src={vector1org}  width={30}/>
                                    <h2 className='font-family text-white ms-4 p-0 m-0'>We understand the unique needs</h2>
                                    <div className='text-end'>
                                        <img src={vector2org}  width={200} className='me-5'/>
                                    </div>
                                </div>
                            </div>
                            <p className='text-white mt-4'>At AeroConsultant, we understand the unique needs and challenges faced by our clients. That&#39;s why
we offer a comprehensive range of services designed to cater to your specific requirements. Whether
you&#39;re seeking Lease Transition Management, CAMO Services, or Digital Records &amp; Operations
management for your asset, our team of skilled professionals is equipped with the knowledge and
expertise to deliver outstanding solutions tailored to your business.</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                            <img src={banner} className='w-100'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className='container mb-5'  style={{marginTop: '180px'}}>
            {/* <div className='text-center'>
                <figcaption className="blockquote-footer fs-5 text-primary">
                    <cite title="Source Title ">Features</cite>
                </figcaption>
            </div> */}

            <div className='row mt-5 pt-3 mb-5'>
                <div className='col-md-6'>
                    <div>
                        <img src={banner2} className='w-100'/>
                    </div>
                </div>
                <div className='col-md-6 d-flex align-items-center'>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <div className='ceo'>
                                <img src={textVec}  width={30}/>
                                <h2 className='font-family text-dark ms-4 p-0 m-0'>Our commitment to quality is unwavering</h2>
                                <div className='text-end'>
                                    <img src={textVec2}  width={200} className='me-5'/>
                                </div>
                            </div>
                        </div>
                        <p className='text-secondary mt-4'>Our commitment to quality is unwavering. We strive for excellence in every aspect of our work, from
                        the initial consultation to the final deliverables. With a customer-centric approach, we prioritize your
                        satisfaction and success, ensuring that our solutions not only meet but exceed your expectations.</p>
                    </div>
                    
                </div>
            </div>
        </div>




        <div className='bg-dark-shade mb-5 pb-5 pt-1' style={{marginTop: '180px'}}>
            <div className='container'>
                <div className='row mt-5'>
                
                    <div className='col-md-6 text-white d-flex align-items-center'>
                        <div>
                            <div className='d-flex justify-content-center'>
                                <div className='ceo'>
                                    <img src={vector1org}  width={30}/>
                                    <h2 className='font-family text-white ms-4 p-0 m-0'>What sets us apart is our team of highly skilled professionals</h2>
                                    <div className='text-end'>
                                        <img src={vector2org}  width={200} className='me-5'/>
                                    </div>
                                </div>
                            </div>
                            <p className='text-white mt-4'>What sets us apart is our team of highly skilled professionals. Our experts bring a wealth of
experience and a deep understanding of Aviation to the table. They stay abreast of the latest trends
and technologies, allowing us to provide innovative and cutting-edge solutions that drive your
business forward.</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                            <img src={banner} className='w-100'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className='container mb-5'  style={{marginTop: '180px'}}>

            <div className='row mt-5 pt-3 mb-5'>
                <div className='col-md-6'>
                    <div>
                        <img src={banner2} className='w-100'/>
                    </div>
                </div>
                <div className='col-md-6 d-flex align-items-center'>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <div className='ceo'>
                                <img src={textVec}  width={30}/>
                                <h2 className='font-family text-dark ms-4 p-0 m-0'>Collaboration is at the heart of our process.</h2>
                                <div className='text-end'>
                                    <img src={textVec2}  width={200} className='me-5'/>
                                </div>
                            </div>
                        </div>
                        <p className='text-secondary mt-4'>Collaboration is at the heart of our process. We believe in working closely with our clients, fostering a
strong partnership built on trust and open communication. By truly understanding your goals and
challenges, we can develop customized strategies that address your unique needs, helping you
achieve your desired outcomes.</p>
                    </div>
                    
                </div>
            </div>
        </div>




        <div className='bg-dark-shade mb-5 pb-5 pt-1' style={{marginTop: '180px'}}>
            <div className='container'>
                <div className='row mt-5'>
                
                    <div className='col-md-6 text-white d-flex align-items-center'>
                        <div>
                            <div className='d-flex justify-content-center'>
                                <div className='ceo'>
                                    <img src={vector1org}  width={30}/>
                                    <h2 className='font-family text-white ms-4 p-0 m-0'>We take pride in our track record of success</h2>
                                    <div className='text-end'>
                                        <img src={vector2org}  width={200} className='me-5'/>
                                    </div>
                                </div>
                            </div>
                            <p className='text-white mt-4'>We take pride in our track record of success. Over the years, we have had the privilege of serving a
diverse range of clients, from small businesses to large corporations. Our proven expertise and
dedication to excellence have earned us a reputation for delivering tangible results and exceeding
expectations.</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                            <img src={banner} className='w-100'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className='container '  style={{marginTop: '180px', marginBottom: '150px'}}>

            <div className='row mt-5 pt-3 mb-5'>
                <div className='col-md-6'>
                    <div>
                        <img src={banner2} className='w-100'/>
                    </div>
                </div>
                <div className='col-md-6 d-flex align-items-center'>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <div className='ceo'>
                                <img src={textVec}  width={30}/>
                                <h2 className='font-family text-dark ms-4 p-0 m-0'>As we continue to grow and evolve</h2>
                                <div className='text-end'>
                                    <img src={textVec2}  width={200} className='me-5'/>
                                </div>
                            </div>
                        </div>
                        <p className='text-secondary mt-4'>As we continue to grow and evolve, we remain committed to staying ahead of the curve. We invest
                            in continuous learning and development, ensuring that our team is equipped with the latest
                            knowledge and skills to tackle emerging challenges in Aviation. Our forward-thinking approach allows
                            us to anticipate market trends and provide you with strategic guidance that keeps you ahead of the
                            competition.</p>
                        <p className='text-secondary mt-4'>
                        Contact us today to learn more about how we can help you achieve your business goals.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>


        {/* Why we choose Sections */}
        {/* <div className='container bg-light py-5 rounded-5' style={{marginTop: '130px', marginBottom: '150px'}}>
            <div className='d-flex justify-content-center pt-5'>
                <div className='w-50 ceo'>
                    <figcaption className="blockquote-footer fs-5 text-center text-primary">
                        <cite title="Source">Why Choose</cite>
                    </figcaption>
                    <div className='text-center w-50'>
                        <img src={textVec} className='me-5' width={30}/>
                    </div>
                    <h2 className='font-family text-center ms-5 p-0 m-0'>Why We Choose Us</h2>
                    <div className='text-end'>
                        <img src={textVec2}  width={200} className='me-5'/>
                    </div>
                </div>
            </div>
            <div className='text-center d-flex justify-content-center'>
                <p className='text-secondary w-75'>I will set up the project by integrating the purchased theme, implementing Redux Saga for state management, and connecting to the existing APIs and WebSocket server. </p>
            </div>
            <div className='row px-4 mt-5'>
                <div className='col-md-4 my-3 border-end'>
                    <WeChooseCard icon={'manage_history'}/>
                </div>
                <div className='col-md-4 my-3 border-end'>
                    <WeChooseCard icon={'manage_accounts'}/>
                </div>
                <div className='col-md-4 my-3'>
                    <WeChooseCard icon={'manage_search'}/>
                </div>
            </div>
        </div> */}


        <Footer/>

    </>
  )
}

export default About
