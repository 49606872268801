import React, { useState, useEffect } from "react";
import Navbarv2 from "../global-components/Navbarv2";
import textVec from "../../assets/img/vec1.png";
import textVec2 from "../../assets/img/vec2.png";
import BlogCard from "../section-components/BlogCard";
import sliderImg1 from "../../assets/img/01.jpeg";
import sliderImg2 from "../../assets/img/02.jpg";
import banner from "../../assets/img/about-banner.png";
import Footer from "../global-components/Footer";
import bannerShadow from "../../assets/img/banner-shadow.png";
import { Link } from "react-router-dom";
import axios from "axios";

const Blog = () => {

  const [bannerImageTitle, setBannerImageTitle] = useState(null)
  useEffect(() => {
    const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
    fetch("https://admin.aeroconsultant.fr/api/blog-page-image", {
      headers: {
        "X-API-Key": apiKey,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBannerImageTitle(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
    // process.env.REACT_APP_ADMIN_URL +
    fetch("https://admin.aeroconsultant.fr/api/blogs", {
      headers: {
        "X-API-Key": apiKey,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  return (
    <>
      <Navbarv2 />

      <nav className="container mt-4" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/" className="text-decoration-none text-primary">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/blogs" className="text-decoration-none text-secondary">
              Blogs
            </Link>
          </li>
        </ol>
      </nav>
      {/* banner section */}
      <div className="px-4 my-4">
        <div className="rounded-5 bg-light">
          <div className="container py-4">
            <div className="row">
              <div
                style={{ zIndex: "100" }}
                className="col-md-6 d-flex align-items-center justify-content-center"
              >
                <div className="">
                  <div className="">
                    <img src={textVec} width={30} className="me-5" />
                  </div>
                  <h2 style={{ fontSize: '45px' }} className='font-family header-heading'>
                    {
                      bannerImageTitle !== null && bannerImageTitle !== undefined && bannerImageTitle.length > 0 && bannerImageTitle[0].title ? (
                        bannerImageTitle[0].title
                      ) : (
                        <>We build bridges and Our Blogs & Latest News</>
                      )
                    }
                  </h2>
                  <div className="text-start position-relative">
                    <img
                      src={textVec2}
                      style={{
                        transform: "rotate(124deg)",
                        top: "-220px",
                        left: "-50px",
                      }}
                      width={200}
                      className="position-absolute"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ zIndex: "100" }}>
                {
                  bannerImageTitle !== null && bannerImageTitle !== undefined && bannerImageTitle.length > 0 && bannerImageTitle[0].image ? (
                    <img src={`https://admin.aeroconsultant.fr/images/${bannerImageTitle[0].image}`} width={'120%'} className='service-banner-img' />
                  ) : (
                    <img src={`${banner}`} width={'120%'} className='service-banner-img' />
                  )
                }

              </div>
            </div>
          </div>
          <img
            style={{ top: "300px", zIndex: "1" }}
            src={bannerShadow}
            width={"100%"}
            className="position-absolute banner-shadow"
          />
        </div>
      </div>

      {/* blogs Cards Start */}
      <div className="container" style={{ marginTop: "80px" }}>
        {/* Search Blogs methods */}
        {/* <div className="my-3 d-flex  align-items-center justify-content-between flex-wrap">
          <div className="">
            <input
              type="text"
              placeholder="Search articles ..."
              className="form-control"
            />
          </div>
          <div className="my-3 d-flex align-items-center justify-content-between flex-wrap">
            <div className="">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <i className="material-icons text-secondary">sort</i>
                  <p className="text-secondary p-0 m-0">Category:</p>
                </div>
                <select
                  className="form-select ms-3 w-100"
                  aria-label="Default select example"
                >
                  <option selected disabled>
                    All
                  </option>
                  <option value="1">Oil</option>
                  <option value="2">News</option>
                  <option value="3">Marketing</option>
                </select>
              </div>
            </div>

            <div className="w-25 text-end">
              <button
                className="btn btn-outline-primary px-4 me-2 rounded-pill"
                type="button"
                id="light-btn"
              >
                Search
              </button>
            </div>
          </div>
        </div> */}
        {/* All Blogs Cards  */}
        <div className="row g-5 mt-3 mb-5">
          {blogs.map((blog, index) => (
            <div className="col-md-4 my-3" key={index}>
              <BlogCard
                title={blog.title}
                category={blog.category}
                // process.env.REACT_APP_ADMIN_URL + 
                img={`https://admin.aeroconsultant.fr/images/` + blog.image}
                readTime={blog.readtime}
                subtitle={blog.subtitle.substr(0, 100) + '...'}
                id={blog.id}
              />
            </div>
          ))}
        </div>
      </div>


      <Footer />
    </>
  );
};

export default Blog;
