import React from 'react'

const ClientsCard = (props) => {
  return (
    <div className='shadow rounded-5 my-4 mb-5 client-card text-center p-4 mx-4'>
      <img src={'https://admin.aeroconsultant.fr/images/' + props.img} className='client-img w-100' />
    </div>
  )
}

export default ClientsCard
