import React from 'react'
import logo from '../../assets/img/logo-light.png';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <>
<div className="">
  <footer
          className="text-center footer text-lg-start text-white"
          >
    <div className="container p-4 pb-0">
      <section className="">
        <div className="row g-0">
          <div className="col-md-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-center mt-3">
            <img src={logo} width={"100%"} />
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto text-center mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Pages
            </h6>
            <p>
              <Link to='/about' className="text-white text-decoration-none">About</Link>
            </p>
            <p>
              <Link to='/blogs' className="text-white text-decoration-none">Blogs</Link>
            </p>
            <p>
              <Link to='/contact' className="text-white text-decoration-none">Career</Link>
            </p>
            <p>
              <Link to='/contact' className="text-white text-decoration-none">Help</Link>
            </p>
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
            <p><i className="fas fa-home mr-3"></i> 16 Avenue du Parc 31700 Blagnac, France</p>
            <p><i className="fas fa-envelope mr-3"></i> Sales@aeroconsultant.fr</p>
            <p><i className="fas fa-phone mr-3"></i> +91 7522001235</p>
            <p><i className="fas fa-print mr-3"></i> +33 789985939</p>
          </div>

          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">Subscribe to Our News Letter</h6>
            <form className="d-flex align-items-center rounded-pill p-2 border news-letter-main">
              <input className="form-control news-letter " type="text" placeholder="Email" />
              <button className="btn btn-primary rounded-pill" type="button">Subscribe</button>
            </form>
          </div>

        </div>
      </section>

      <hr className="my-3" />

      <section className="p-3 pt-0">
        <div className="row d-flex align-items-center">
          <div className="col-md-12  text-center">
            <div className="p-3">
            © 2024 Aero Consultant. All rights reserved.
            </div>
          </div>
        </div>
      </section>
    </div>
  </footer>
</div>
    </>
  )
}

export default Footer
