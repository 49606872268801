import React, { useState, useEffect } from 'react'
import Navbarv2 from '../global-components/Navbarv2'
import textVec from '../../assets/img/vec1.png';
import textVec2 from '../../assets/img/vec2.png';
import sliderImg1 from '../../assets/img/Map-Image-01.jpg';
import aero from '../../assets/img/aero.png';
import ContactForm from '../section-components/ContactForm';
import Footer from '../global-components/Footer';
import { Link } from 'react-router-dom';


const Contact = () => {

    const [bannerImageTitle, setBannerImageTitle] = useState(null)
    useEffect(() => {
        const apiKey = "asdhagf654sgfAFRRGG621serteg1sADFJGRBd62g1s5";
        fetch("https://admin.aeroconsultant.fr/api/contact-page-image", {
            headers: {
                "X-API-Key": apiKey,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setBannerImageTitle(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <>
            <Navbarv2 />

            <nav className='container mt-4' aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-primary'>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/contact" className='text-decoration-none text-secondary'>Contact</Link></li>
                </ol>
            </nav>

            <div className='container position-relative'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center w-75 my-5'>
                        <div className='text-center w-25'>
                            <img src={textVec} width={30} />
                        </div>
                        <h2 style={{ fontSize: '45px' }} className='font-family header-heading'>{bannerImageTitle !== null ? bannerImageTitle[0].title : " Get in touch with us for more information"}</h2>
                        <div className='text-start position-relative'>
                            <img src={textVec2} style={{ transform: 'rotate(124deg)', top: '-85px', left: '95px' }} width={200} className='position-absolute' />
                        </div>
                    </div>
                    <img src={aero} width={200} className='position-absolute contact-aero' style={{ right: '65px', top: '55px', transform: 'rotate(16deg)' }} />
                </div>
            </div>

            <div className='container'>
                {
                    bannerImageTitle !== null && bannerImageTitle !== undefined && bannerImageTitle.length > 0 && bannerImageTitle[0].image ? (
                        <img src={`https://admin.aeroconsultant.fr/images/${bannerImageTitle[0].image}`} width={'100%'} height={'500px'} className='rounded-5' />
                    ) : (
                        <img src={`${sliderImg1}`} width={'100%'} height={'500px'} className='rounded-5' />
                    )
                }
            </div>


            <div className='container my-5'>
                <div className='row'>
                    <div className='col-md-6 my-3'>
                        <h2 className='font-family'>Get in touch</h2>
                        <ContactForm />
                    </div>
                    <div className='col-md-6 my-3'>
                        <iframe
                            className='rounded-5'
                            width={"100%"}
                            height={"450"}
                            frameborder={"0"}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.183643805502!2d-122.4194155844029!3d37.77492957965033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580fa17d82e7f%3A0x4a4b801d81781555!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sca!4v1597349541015!5m2!1sen!2sca"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>

            <div className='container text-center'>
                <h3 className='font-family'>OR</h3>
            </div>

            <div className='container my-5'>
                <div className='row'>
                    <div className='col-md-4 my-3'>
                        <div className='rounded-5 bg-light'>
                            <div className='card-body text-center'>
                                <h4 className='font-family'>Support</h4>
                                <p className='text-secondary'>Our friendly team is here to help.</p>
                                <h6 className='text-primary'>consultant@aeroconsultant.fr</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 my-3'>
                        <div className='rounded-5 bg-light'>
                            <div className='card-body text-center'>
                                <h4 className='font-family'>Sales</h4>
                                <p className='text-secondary'>Question Or queries? Get in touch!</p>
                                <h6 className='text-primary'>Sales@aeroconsultant.fr</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 my-3'>
                        <div className='rounded-5 bg-light'>
                            <div className='card-body text-center'>
                                <h4 className='font-family'>Phone</h4>
                                <p className='text-secondary'>Mon-Fri from 8am to 5pm.</p>
                                <h6 className='text-primary'>+91 7522001235 <span className='text-secondary lead mx-2'>OR</span> +91 7522001235</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Contact
